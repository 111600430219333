<template>
  <ul class="menu-nav">
    <!--     
    <router-link
      to="/admin/dashboard"
      v-slot="{href, navigate, isActive, isExactActive}"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->

    <router-link v-can="'news'" to="/admin/news" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-newspaper"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.news') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'news_category'" to="/admin/news_category" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-newspaper"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.news_category') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'project'" to="/admin/project_description" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-1"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.project_description') }}</span>
        </a>
      </li>
    </router-link>
<!-- 
    <router-link v-can="'project_map'" to="/admin/project_map" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-map"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.project_map') }}</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      v-can="'organization_chart'"
      to="/admin/organization_chart"
      v-slot="{href, navigate, isActive, isExactActive}"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-group"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.organization_chart') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'research_data'" to="/admin/research_data" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-icon svg-icon svg-icon-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M1,12 L1,14 L6,14 L6,12 L1,12 Z M0,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,15 C21,15.5522847 20.5522847,16 20,16 L0,16 C-0.55228475,16 -1,15.5522847 -1,15 L-1,11 C-1,10.4477153 -0.55228475,10 0,10 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  transform="translate(10.000000, 13.000000) rotate(-225.000000) translate(-10.000000, -13.000000) "
                />
                <path
                  d="M17.5,12 L18.5,12 C18.7761424,12 19,12.2238576 19,12.5 L19,13.5 C19,13.7761424 18.7761424,14 18.5,14 L17.5,14 C17.2238576,14 17,13.7761424 17,13.5 L17,12.5 C17,12.2238576 17.2238576,12 17.5,12 Z M20.5,9 L21.5,9 C21.7761424,9 22,9.22385763 22,9.5 L22,10.5 C22,10.7761424 21.7761424,11 21.5,11 L20.5,11 C20.2238576,11 20,10.7761424 20,10.5 L20,9.5 C20,9.22385763 20.2238576,9 20.5,9 Z M21.5,13 L22.5,13 C22.7761424,13 23,13.2238576 23,13.5 L23,14.5 C23,14.7761424 22.7761424,15 22.5,15 L21.5,15 C21.2238576,15 21,14.7761424 21,14.5 L21,13.5 C21,13.2238576 21.2238576,13 21.5,13 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
          </span>
          <span class="menu-text">{{ $t('MAIN_MENU.research_data') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'researcher'" to="/admin/researcher" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon la la-user-graduate icon-lg"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.researcher') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'about_us'" to="/admin/about_us" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-users-1"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.about_us') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'contact_us'" to="/admin/contact_us" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-email"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.contact_us') }}</span>
        </a>
      </li>
    </router-link>

    <!-- GIS Data -->
    <li class="menu-section">
      <h4 class="menu-text">GIS</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link v-can="'gis'" to="/admin/gis" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-map-location-dot"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.gis_data') }}</span>
        </a>
      </li>
    </router-link>

    <router-link v-can="'gis_image'" to="/admin/gis_image" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-map-location-dot"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.gis_image') }}</span>
        </a>
      </li>
    </router-link>

    <!-- CTD Data -->
    <li class="menu-section">
      <h4 class="menu-text">CTD</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/ctd'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.ctd_data') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link v-can="'ctd'" :to="{name: 'ctd_data.list'}" v-slot="{href, navigate, isActive, isExactActive}">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('ctd_data.batch_data_list') }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-can="'ctd'"
            :to="{name: 'ctd_data.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-can="'ctd'"
            :to="{name: 'ctd_data.list_parameter'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_paramenter') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- Timebase Data -->
    <li class="menu-section">
      <h4 class="menu-text">Time Series</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/temp'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.temp') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link v-can="'temp'" :to="{name: 'temp.list'}" v-slot="{href, navigate, isActive, isExactActive}">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.temp_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'temp'"
            :to="{name: 'temp.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/salinity'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.salinity') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-can="'salinity'"
            :to="{name: 'salinity.list'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.salinity_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'salinity'"
            :to="{name: 'salinity.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- Station Data -->
    <li class="menu-section">
      <h4 class="menu-text">Station Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/waste'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.waste') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link v-can="'waste'" :to="{name: 'waste.list'}" v-slot="{href, navigate, isActive, isExactActive}">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.waste_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'waste'"
            :to="{name: 'waste.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'waste'"
            :to="{name: 'waste.list_parameter'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_paramenter') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/waterquality'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.waterquality') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-can="'waterquality'"
            :to="{name: 'waterquality.list'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.waterquality_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'waterquality'"
            :to="{name: 'waterquality.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'waterquality'"
            :to="{name: 'waterquality.list_parameter'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_paramenter') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/sediment'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.sediment') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-can="'sediment'"
            :to="{name: 'sediment.list'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.sediment_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'sediment'"
            :to="{name: 'sediment.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'sediment'"
            :to="{name: 'sediment.list_parameter'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_paramenter') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/admin/biodiversity'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-line"></i>
        <span class="menu-text">{{ $t('MAIN_MENU.biodiversity') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-can="'biodiversity'"
            :to="{name: 'biodiversity.list'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.biodiversity_data') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'biodiversity'"
            :to="{name: 'biodiversity.list_station'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ctd_station') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-can="'biodiversity'"
            :to="{name: 'biodiversity.list_genus'}"
            v-slot="{href, navigate, isActive, isExactActive}"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('biodiversity.list_genus') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- Setttings -->
    <li class="menu-section">
      <h4 class="menu-text">Settings</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link v-can="'user'" to="/admin/user" v-slot="{href, navigate, isActive, isExactActive}">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-user"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.user_setting') }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
  },
  computed: {},
};
</script>

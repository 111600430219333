<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2"> 2020 &nbsp;&copy;&nbsp; </span>
        Phuket Innova Co.,Ltd.
      </div>
      <div class="nav nav-dark">
        <!-- <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link pr-3 pl-0"
        >
          About
        </a>
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link px-3"
        >
          Team
        </a>
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link pl-3 pr-0"
        >
          Contact
        </a> -->
        <span class="text-muted font-weight-bold mr-2">
          Version {{ VUE_APP_VERSION }} Build {{ VUE_APP_BUILD_DATE }}
        </span>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'KTFooter',
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    },
    VUE_APP_VERSION() {
      return process.env.VUE_APP_VERSION;
    },
    VUE_APP_BUILD_DATE() {
      return process.env.VUE_APP_BUILD_DATE;
    },
  },
};
</script>
